<template>
  <BasePage :isLoaded="!!Product">
    <template v-slot:content>
      <v-container class="product-page-container">
        <v-row class="mb-16 pt-16">
          <v-col
            cols="12"
            md="5"
            offset-md="1"
            ref="contentContainer"
          >
            <div ref="gallery" class="gallery-container">
              <div
                v-if="!!backRoute"
                class="mb-16"
              >
                <Cta
                  :to="backRoute.fullPath"
                  :reverse="true"
                  action="internal"
                  label="Back"
                />
              </div>
              <div
                v-if="!!Product._referents.CollectionReferents.items.length"
                class="mb-6"
              >
                <Cta
                  :to="{ name: 'CollectionPage', params: { slug: Product._referents.CollectionReferents.items[0]._slug } }"
                  :reverse="true"
                  action="internal"
                  label="View Collection"
                />
              </div>
              <Gallery
                v-if="!!(Product.gallery && Product.gallery.length)"
                :slides="Product.gallery"
                :slideCaption="false"
                :slidesPerView="1"
                class="pa-0"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="!!(Product.gallery && Product.gallery.length) ? 4 : 10"
            offset-md="1"
          >
            <h2 class="h2 -sci-gradient">{{ Product.title }}</h2>
            <MarkdownBlock
              :text="Product.subTitle"
              :inline="false"
              tag="div"
            />
            <MarkdownBlock
              :text="Product.description"
              :inline="false"
              tag="div"
              class="product-section-content"
            />
            <ProductModularContent
              v-if="!!Product.modularContent"
              :contents="Product.modularContent"
              :colors="Product.colors"
            />
          </v-col>
        </v-row>
      </v-container>
      <ProductVideoTutorial
        v-if="!!(Product.videoTutorial || Product.videoFileTutorial)"
        :video="(Product.videoTutorial || Product.videoFileTutorial)"
        :poster="Product.videoFileTutorialPoster ? Product.videoFileTutorialPoster.id : null"
        :title="Product.videoTutorialTitle || 'Product Review'"
        :subtitle="Product.videoTutorialDescription || 'Our Expert’s portrait of this product'"
      />
      <CardCarousel
        v-if="!!Product.similarProducts"
        :slides="Product.similarProducts.filter(product => (!!product && !product.isPrivate))"
        title="Similar Product"
      />
    </template>
  </BasePage>
</template>

<script>
import { optimizedImageURL } from '@/services/OptimizedImage'
import { MarkdownBlock } from '@monogrid/vue-lib'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import GetProduct from '@/graphql/GetProduct.gql'

import BasePage from '@/organisms/BasePage'
import Gallery from '@/molecules/Gallery.vue'
import CardCarousel from '@/molecules/CardCarousel.vue'

import ProductModularContent from '@/molecules/ModularContent.vue'
import ProductVideoTutorial from '@/molecules/modular_content/ModularVideoTutorial.vue'
import Cta from '@/molecules/Cta.vue'

export default {
  name: 'ProductPage',
  apollo: {
    Product: {
      query: GetProduct,
      variables () {
        return {
          slug: this.slug
        }
      },
      async result (res) {
        if (!res.data.Product) {
          this.$router.push({ name: 'Home' })
        }
      }
    }
  },
  components: {
    MarkdownBlock,
    BasePage,
    Gallery,
    CardCarousel,
    ProductModularContent,
    ProductVideoTutorial,
    Cta
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.$events.on('page-entered', this.onPageEnter)
  },
  beforeDestroy () {
    this.$events.off('page-entered', this.onPageEnter)
    this.tl && this.tl.kill()
  },
  computed: {
    backRoute () {
      return this.$root.backRoute &&
        this.$root.backRoute.params.slug !== this.Product._referents.CollectionReferents.items[0]._slug
        ? this.$root.backRoute
        : false
    }
  },
  methods: {
    onPageEnter () {
      if (this.Product.gallery && this.Product.gallery.length) {
        this.$nextTick(() => {
          ScrollTrigger.matchMedia({
            // desktop
            '(min-width: 960px)': this.desktopTimeline
          })
        })
      }
    },
    desktopTimeline () {
      this.tl = ScrollTrigger.create({
        pin: this.$refs.gallery,
        trigger: this.$refs.contentContainer,
        start: () => {
          const bb = this.$refs.contentContainer.getBoundingClientRect()
          return `top +=${bb.top}`
        },
        end: () => {
          const bbC = this.$refs.contentContainer.getBoundingClientRect()
          const bb = this.$refs.gallery.getBoundingClientRect()
          return `+=${bbC.height - bb.height}`
        },
        pinSpacing: false
      })
      return this.tl
    }
  },
  metaInfo: function () {
    if (this.Product) {
      this.$events.$emit('global-breadcrumbs', [
        {
          name: 'ProductsShowcase',
          label: 'Virtual Lab'
        },
        {
          name: 'CollectionPage',
          label: this.Product._referents.CollectionReferents.items[0].title,
          params: {
            slug: this.Product._referents.CollectionReferents.items[0]._slug
          }
        }
      ])

      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.Product.metaTitle },
        { vmid: 'description', name: 'description', content: this.Product.metaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.Product.metaDescription }
      ]

      if (this.Product.metaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.Product.metaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.Product.metaTitle ? this.Product.metaTitle : this.Product.title,
        meta
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-page-container {
  position: relative;
}

h2 {
  margin-bottom: rem(12px);

  @include breakpoint ('sm-and-down') {
    margin-top: rem(57px);
  }
}

.product-section-content {
  margin: rem(36px) 0;
}
</style>
